import * as React from 'react';
import {faTimes} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form} from 'formik';
import {ReactNode} from 'react';
import {ModalBackdrop} from '../ModalBackdrop';

interface ContentModalProps {
    children: ReactNode;
    e2eLabel?: string;
    footer?: JSX.Element;
    footerStyleCustom?: boolean;
    form?: boolean;
    hideHeader?: boolean;
    noBottomPadding?: boolean;
    onClose: () => void;
    show: boolean;
    title?: string | JSX.Element;
}

export const ContentModal = ({
    children,
    e2eLabel,
    footer,
    footerStyleCustom,
    form,
    hideHeader,
    noBottomPadding,
    onClose,
    show,
    title,
}: ContentModalProps) => {
    /**
     * Contents template
     */
    const contents = (
        <>
            {!hideHeader && (
                <div className="tw-flex tw-items-center tw-py-2 tw-text-white tw-px-4 tw-justify-between tw-bg-gray-600 tw-flex-shrink-0">
                    {title && <h4 className="tw-mb-0 tw-leading-normal">{title}</h4>}
                    <button
                        aria-label="Close"
                        className="tw-rounded-sm tw-leading-normal tw-text-base tw-border tw-border-transparent tw-bg-transparent tw-select-none tw-align-middle tw-text-center tw-inline-block tw-font-normal tw-p-4 -tw-my-4 -tw-mr-4 tw-ml-auto tw-cursor-pointer"
                        data-e2e="closeModal"
                        onClick={() => onClose()}
                        type="button"
                    >
                        <FontAwesomeIcon
                            color="#e5e5e5"
                            icon={faTimes}
                            size="3x"
                        />
                    </button>
                </div>
            )}
            <div
                className={`tw-relative tw-p-8 tw-flex tw-flex-col tw-overflow-y-auto ${
                    noBottomPadding ? 'tw-px-8 tw-pt-8 !tw-pb-0' : 'tw-p-8'
                }`}
            >
                {children}
            </div>
            {footer && (
                <div className="tw-fixed md:tw-relative tw-bottom-0 md:tw-bottom-auto tw-bg-white tw-w-full tw-z-[1060] tw-border-t-0 tw-p-7 tw-justify-end tw-items-center tw-flex-wrap tw-flex tw-flex-shrink-0">
                    {footerStyleCustom ? (
                        footer
                    ) : (
                        <div className={footerStyleCustom ? `` : `tw-grid lg:!tw-block tw-w-full lg:tw-w-auto`}>{footer}</div>
                    )}
                </div>
            )}
        </>
    );

    /**
     * Template
     */
    return (
        <>
            <ModalBackdrop
                onClick={onClose}
                show={show}
            />
            <div
                className={`tw-p-0 tw-h-full tw-right-0 tw-justify-self-center tw-w-full tw-z-[1050] tw-left-0 tw-justify-center tw-top-0 tw-fixed tw-overflow-x-hidden tw-overflow-y-auto tw-max-w-none sm:tw-min-h-[calc(100%-1.75rem)] md:tw-max-w-[920px] md:tw-w-[80vw] ${
                    show ? 'tw-block' : 'tw-hidden'
                }`}
                data-e2e={e2eLabel}
            >
                <div className="tw-relative tw-items-center tw-w-full tw-h-full tw-max-w-none tw-max-h-full tw-m-0 tw-top-0 tw-flex md:tw-my-7 md:tw-mx-auto sm:tw-min-h-[calc(100%-3.5rem)] md:tw-max-h-[calc(100%-3.5rem)] md:tw-max-w-[920px] md:tw-w-[80vw] tw-justify-center tw-flex-col">
                    <div
                        className={`tw-h-full md:tw-h-auto tw-relative tw-flex tw-flex-col tw-w-full tw-pointer-events-auto tw-bg-white tw-outline-0 tw-rounded-none tw-border-none tw-overflow-hidden tw-max-h-none`}
                    >
                        {form ? (
                            <Form
                                className="tw-w-full"
                                style={{display: `contents`}}
                            >
                                {contents}
                            </Form>
                        ) : (
                            contents
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
