import * as React from 'react';
import {useEffect} from 'react';

import {BodyScroll} from '../ScrollHandler';

interface ModalBackdropProps {
    onClick: () => void;
    show: boolean;
}

export const ModalBackdrop = ({show, onClick}: ModalBackdropProps) => {
    // Prevent body scroll on render
    useEffect(() => {
        BodyScroll(!show);
    }, [show]);

    /**
     * Template
     */
    return (
        <div
            className={`tw-fixed tw-overflow-y-hidden tw-z-[1040] tw-opacity-50 tw-top-0 tw-left-0 tw-w-[100vw] tw-h-[100vh] tw-bg-black${
                show ? '' : ' tw-hidden'
            }`}
            onClick={onClick}
        />
    );
};
